.container {
  margin: 0 auto;
}
.hero-featured-image {
  cursor: pointer;
  background: #5a78fc;
  border-radius: 100%;
  padding: 2rem;
  width: 300px;
  box-shadow:
    inset 0 0 0 1rem #7b92fa,
    inset 0 0 0 2rem #718afb,
    inset 0 0 0 0 transparent;
}
.hero-featured-image:hover {
  box-shadow:
    inset 0 0 0 1rem #607cf9,
    inset 0 0 0 2rem #778ef5,
    inset 0 0 0 0 transparent;
  background: #7b92fa;
}
@media screen and (min-width: 768px) {
  .hero-featured-image {
    cursor: pointer;
    background: #5a78fc;
    border-radius: 100%;
    padding: 2rem;
    width: 100%;
    box-shadow:
      inset 0 0 0 2rem #7b92fa,
      inset 0 0 0 4rem #718afb,
      inset 0 0 0 0 transparent;
  }
  .hero-featured-image:hover {
    box-shadow:
      inset 0 0 0 2rem #607cf9,
      inset 0 0 0 4rem #778ef5,
      inset 0 0 0 0 transparent;
    background: #7b92fa;
  }
}